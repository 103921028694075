import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"


const TeamPage = () => {

    const { allStrapiTeamMember } = useStaticQuery(graphql`
    query {
        allStrapiTeamMember {
            nodes {
              bio
              id
              instaUrl
              role
              name
              siteUrl
              photo {
                alternativeText
                localFile {
                    childImageSharp {
                        gatsbyImageData
                    }
                }
              }
            }
        }
    }
  `)

    function printMembers(teamMembersArray) {
        return teamMembersArray.nodes.map((teamMember) => {
            return (
                <div class="w-full mb-16">
                    <div class="flex">
                        <div class="lg:w-1/6"></div>
                        <div class="lg:w-2/6">
                            <GatsbyImage
                                class="rounded-xl"
                                image={getImage(teamMember.photo?.localFile)}
                            />
                        </div>
                        <div class="lg:w-3/6 ml-6">
                            <div class="text-2xl font-bold">{teamMember.name}</div>
                            <div class="text-tpOrange text-xl font-semibold">{teamMember.role}</div>
                            <div class="text-xl mt-3 text-tpBlackish tracking-wider leading-5">{teamMember.bio}</div>
                            <div></div>
                        </div>
                    </div>
                </div>
            )
        })
    }

    return (
        <Layout>
            <Seo seo={{ metaTitle: "Meet the team" }} />
            <div className="">
                <main class="container flex flex-wrap mt-24">
                    <div class="lg:w-1/5 md:w-5/6 mb-20 w-5/6">
                        <h1 class="text-5xl font-bold text-tpBlue leading-tight">Meet the team</h1>
                    </div>
                    <div class="lg:w-4/5">
                        <ul>
                            {printMembers(allStrapiTeamMember)}
                        </ul>
                    </div>
                </main>
            </div>
        </Layout>
    )
}

export default TeamPage
